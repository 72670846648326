import * as React from "react";
import { makeStyles } from 'tss-react/mui';

import {List, Datagrid, TextField, Show, Tab, UrlField, Pagination, Filter, SelectInput} from 'react-admin';
import {EditButton, SimpleForm, Edit, TextInput, ImageField} from 'react-admin';
import {TabbedShowLayout, Create, TabbedForm, FormTab} from 'react-admin';
import {CustomShowActions} from "./CustomComponents";
import {ColorInput, ColorField} from "react-admin-color-picker";

export const SourceList = props => {
  return (<List {...props} pagination={<MyPagination/>} filters={<SourcesFilter/>}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="_id" label="Id"/>
      <TextField source="name" label="Display name"/>
      <ImageField source="icon" sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }}/>
      <TextField source="source_type" label="Source type"/>
      <ColorField source="dominant_color" label="Color" />
      <EditButton/>
    </Datagrid>
  </List>)
};

const SourcesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Id" source="_id" />
    <TextInput label="Display name (fr)" source="name" />
    <SelectInput label="Source type" source="source_type" choices={[
      { id: 'feed', name: 'Feed' },
      { id: 'twitter_influencer', name: 'Twitter influencer' },
      { id: 'twitter_media', name: 'Twitter media' },
    ]}/>
  </Filter>
);

export const SourceEdit = props => {
  return (<Edit {...props}>
      <SimpleForm>
        <TextField source="_id" label="Id"/>
        <TextInput source="name" label="Display name"/>
        <ImageField source="icon" sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }}/>
        <TextInput source="icon" label="icon" fullWidth={true}/>
        <SelectInput label="Source type" source="source_type" choices={[
          { id: 'feed', name: 'Feed' },
          { id: 'twitter_influencer', name: 'Twitter influencer' },
          { id: 'twitter_media', name: 'Twitter media' },
        ]}/>
        <ColorInput source="dominant_color" label="Color"/>
      </SimpleForm>
    </Edit>)
};

export const SourceShow = (props) => {
  return (
    <Show {...props} actions={<CustomShowActions/>}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="_id" label="Id"/>
          <TextField source="name" label="Display name"/>
          <ImageField source="icon" title="icon" sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }}/>
          <UrlField source="icon" label="Icon url"/>
          <TextField source="source_type" label="Source type"/>
          <ColorField source="dominant_color" label="Color"/>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const SourceCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput source="_id" label="Id"/>
          <TextInput source="name" label="Display name"/>
          <TextInput source="icon" label="Icon url" fullWidth={true}/>
          <SelectInput label="Source type" source="source_type" choices={[
            { id: 'feed', name: 'Feed' },
            { id: 'twitter_influencer', name: 'Twitter influencer' },
            { id: 'twitter_media', name: 'Twitter media' },
          ]}/>
          <ColorInput source="dominant_color" label="Color"/>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

const MyPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000, 2000]} {...props} />
);

// const useStyles = makeStyles()({
//   imgContainer: {
//     '& img': {
//       height: 50,
//       width: 50,
//       objectFit: "contain"
//     }
//   }
// });
