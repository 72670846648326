import * as React from "react";
import { Admin, Resource} from 'react-admin';
import dataProvider from './providers/dataProvider';
import authProvider from "./providers/authProvider";

import {UserList, UserEdit, UserShow} from "./components/users";
import {ModuleMetadataList, ModuleMetadataEdit, ModuleMetadataShow} from "./components/moduleMetadatas";
import {ModuleList, ModuleEdit, ModuleShow, ModuleCreate} from "./components/modules";
import {TopicList, TopicEdit, TopicShow, TopicCreate} from "./components/topics";
import {AudioDirectionList, AudioDirectionShow, AudioDirectionEdit} from "./components/audioDirections";
import {JungleModuleList, JungleModuleShow, JungleModuleEdit} from "./components/jungleModules";
import {JunglePodcastList, JunglePodcastShow, JunglePodcastEdit} from "./components/junglePodcasts";
import {JungleAudioDirectionList, JungleAudioDirectionShow, JungleAudioDirectionEdit} from "./components/jungleAudioDirections";
import {JungleCloudContextList, JungleCloudContextShow, JungleCloudContextEdit} from "./components/jungleCloudContext";
import {SourceList, SourceShow, SourceEdit, SourceCreate} from "./components/sources";
import {SpeechRecoModelsList, SpeechRecoModelsCreate, SpeechRecoModelsEdit, SpeechRecoModelsShow} from "./components/speechRecoModels";
import {NlpFilterList, NlpFilterEdit, NlpFilterShow} from "./components/nlpFilter";
import {GlobalConfigEdit, GlobalConfigList, GlobalConfigShow} from "./components/globalConfig";
import MyLayout from "./components/MyLayout";

import UserIcon from '@mui/icons-material/Group';
import Cloud from '@mui/icons-material/Cloud';
import Dashboard from '@mui/icons-material/Dashboard';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import Audiotrack from '@mui/icons-material/Audiotrack';
import Settings from '@mui/icons-material/Settings';
import RssFeed from '@mui/icons-material/RssFeed';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import CloudIcon from '@mui/icons-material/Cloud';
import {NewscastCreate, NewscastEdit, NewscastList, NewscastShow} from "./components/newscasts";
import {juiceTheme} from "./styling/juiceTheme";

const App = () =>
  <Admin theme={juiceTheme} layout={MyLayout} dataProvider={dataProvider} authProvider={authProvider}>
    <Resource name="globalConfig" options={{ label: 'Global Configuration' }} list={GlobalConfigList} show={GlobalConfigShow} edit={GlobalConfigEdit} icon={Settings}/>
    <Resource name="user" list={UserList} show={UserShow} edit={UserEdit} icon={UserIcon}/>
    <Resource name="moduleMetadata" options={{ label: 'Module Metadata' }} list={ModuleMetadataList} show={ModuleMetadataShow} edit={ModuleMetadataEdit} icon={Cloud}/>
    <Resource name="module" list={ModuleList} show={ModuleShow} edit={ModuleEdit} create={ModuleCreate} icon={Dashboard}/>
    <Resource name="newsTopics" options={{ label: 'News Topics' }} list={TopicList} show={TopicShow} edit={TopicEdit} create={TopicCreate} icon={LibraryBooks}/>
    <Resource name="newscast" options={{ label: 'Newscasts' }} list={NewscastList} show={NewscastShow} edit={NewscastEdit} create={NewscastCreate} icon={TapAndPlayIcon}/>
    <Resource name="source" list={SourceList} show={SourceShow} edit={SourceEdit} create={SourceCreate} icon={RssFeed}/>
    {/*<Resource name="audioDirection" options={{ label: 'Audio Direction' }} list={AudioDirectionList} show={AudioDirectionShow} edit={AudioDirectionEdit} icon={Audiotrack}/>*/}
    <Resource name="jungleModule" options={{ label: 'Jungle Module' }} list={JungleModuleList} show={JungleModuleShow} edit={JungleModuleEdit} icon={Dashboard}/>
    <Resource name="junglePodcast" options={{ label: 'Jungle Podcasts' }} list={JunglePodcastList} show={JunglePodcastShow} edit={JunglePodcastEdit} icon={Dashboard}/>
    <Resource name="jungleAudioDirection" options={{ label: 'Jungle Audio Direction' }} list={JungleAudioDirectionList} show={JungleAudioDirectionShow} edit={JungleAudioDirectionEdit} icon={Audiotrack}/>
    <Resource name="jungleCloudContext" options={{ label: 'Jungle Cloud Context' }} list={JungleCloudContextList} show={JungleCloudContextShow} edit={JungleCloudContextEdit} icon={CloudIcon}/>
    <Resource name="speechRecoModel" options={{ label: 'Speech Recognition Models' }} list={SpeechRecoModelsList} show={SpeechRecoModelsShow} edit={SpeechRecoModelsEdit} create={SpeechRecoModelsCreate} icon={GraphicEqIcon} />
    <Resource name="nlpFilter" options={{ label: 'NLP Filters' }} list={NlpFilterList} show={NlpFilterShow} edit={NlpFilterEdit} icon={MenuBookIcon} />
  </Admin>;

export default App;
