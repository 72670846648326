import * as React from "react";
import {useEffect, useState} from 'react';

import {
  List,
  Datagrid,
  TextField,
  Show,
  Tab,
  TabbedForm,
  FormTab,
  SelectInput,
  BooleanInput,
  useShowController,
} from 'react-admin';
import {EditButton, Edit, TabbedShowLayout, TextInput} from 'react-admin';

import ButtonRow from './ButtonRow';
import {JsonEditorInput, JsonEditorShow} from "./JsonIDE";
import { makeStyles } from 'tss-react/mui';
import {CustomShowActions, CustomToolbar} from "./CustomComponents";
import {EditorCheatSheet} from "./cheatSheets";
import {podcast_generator_api_url} from "../config";
import {Button, ListItem, ListItemText, List as ListMui, Divider} from "@mui/material";

export const JunglePodcastList = props => {
  return (<List {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" label="Id"/>
      <TextField source="minJuiceVersion" label="Version"/>
      <EditButton/>
    </Datagrid>
  </List>)
};

const GeneratorComponent = (props) => {
  const [episodes, setEpisodes] = React.useState([]);
  const [generating, setGenerating] = React.useState(false);
  const junglePodcastName = props.record.name;

  useEffect(() => {
    if (!episodes.length) {
      getAvailableEpisodes().catch((e) => {console.log('Failed to request episodes: ', e)});
    }
  })

  const getAvailableEpisodes = async () => {
    try {
      const token = localStorage.getItem('token');
      const url = `${podcast_generator_api_url}/availableEpisodes/${junglePodcastName}`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const jsonRes = await res.json();
      setEpisodes(jsonRes.episodes);
    } catch (e) {
      console.log('Failed to retrieve episodes: ', e);
    }
  }

  const generateEpisode = async () => {
    setGenerating(true);
    try {
      const token = localStorage.getItem('token');
      const url = `${podcast_generator_api_url}/generatePodcast/${junglePodcastName}`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        method: 'POST'
      });
      console.log('Received: ', res);
      return {ok: res.ok, error: res.error};
    } catch (e) {
      console.log('Failed to retrieve episodes: ', e);
    }
    setGenerating(false);
  }

  const downloadEpisode = async (episode) => {
    try {
      const token = localStorage.getItem('token');
      const url = `${podcast_generator_api_url}/downloadPodcast/${junglePodcastName}/${episode}`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
      const blob = await res.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.download = episode;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.log('Failed to retrieve episodes: ', e);
    }
  }

  return (
    <div>
      <h2>Podcasts Generator</h2>
      <Button variant="contained" onClick={getAvailableEpisodes}>Update list</Button>
      <ListMui component="nav" aria-label="mailbox folders">
        <Divider/>
        {episodes.map((e) => (
          <ListItem divider>
            <ListItemText primary={e.filename} />
            <Button onClick={() => {
              downloadEpisode(e.filename).catch((e) => {
                console.log('Failed to download episode ', e);
              });
            }}>DOWNLOAD</Button>
            <Button>Upload to Ausha (coming soon)</Button>
          </ListItem>
        ))}
      </ListMui>

      <div>
        <Button variant="contained" disabled={generating} onClick={generateEpisode}>
          {generating ? 'Generating • Please wait' : 'Generate new episode'}
        </Button>
      </div>
      <div></div>
      <Divider/>
    </div>
  )
}

// --------------------------------- Show ---------------------------------

export const JunglePodcastShow = (props) => {
  const {record} = useShowController(props);
  const [collapsed, setCollapsed] = React.useState(1);
  return (<Show {...props} actions={<CustomShowActions/>}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" label="Name"/>
        <TextField source="displayName.fr" label="Display name fr" />
        <TextField source="displayName.en" label="Display name en" />
        <TextField source="minJuiceVersion" label="Version"/>
        <TextField source="description" label="Description"/>
        <TextField source="audioDirection" label="Audio Direction"/>
        <TextField source="offer" label="Offer"/>
        <TextField source="defaultActivated" label="Activated by deafult"/>
        <Divider />
        <GeneratorComponent {...props} record={record}/>
      </Tab>
      <Tab label="Expressions">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="expr" collapsed={collapsed} />
      </Tab>

      <Tab label="Module UI">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="moduleUi" collapsed={collapsed} />
      </Tab>

      <Tab label="Settings">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="settings" collapsed={collapsed} />
      </Tab>

      <Tab label="Trigger">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="trigger" collapsed={collapsed} />
      </Tab>

      <Tab label="UI (old)">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="ui" collapsed={collapsed} />
      </Tab>
      <Tab label="Fetch">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="fetch" collapsed={collapsed} />
      </Tab>
      <Tab label="Message (old)">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="message" collapsed={collapsed} />
      </Tab>
      <Tab label="Sequence">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="sequence" collapsed={collapsed} />
      </Tab>
    </TabbedShowLayout>
  </Show>)
};

// --------------------------------- Edit ---------------------------------

export const JunglePodcastEdit = props => {
  const [collapsed, setCollapsed] = React.useState(1);
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <Edit {...props}>
      <TabbedForm sanitizeEmptyValues={false} toolbar={<CustomToolbar disabled={isDisabled}/>}>

        <FormTab label="Summary">
          <TextInput source="name" label="Id"/>
          <TextInput source="displayName.fr" label="Display name fr" />
          <TextInput source="displayName.en" label="Display name en" />
          <TextInput source="minJuiceVersion" label="Version"/>
          <TextInput source="description" label="Description"/>

          <TextInput label="Jungle Audio Direction" source="audioDirection" />
          <SelectInput source="offer" choices={[
            { id: 'ad', name: 'Ad' },
            { id: 'freemium', name: 'Freemium' },
            { id: 'premium', name: 'Premium' },
            { id: 'admin', name: 'Admin' },
            { id: 'iad', name: 'IAD' },
            { id: 'macif', name: 'MACIF' },
          ]} />
          <BooleanInput label="Default Activated" source='defaultActivated' />
        </FormTab>

        {/* --- Expressions --- */}
        <FormTab label="Expressions">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source='expr' onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

        {/* --- Module UI --- */}
        <FormTab label="Module UI">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source='moduleUi' onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

        {/* --- Settings --- */}
        <FormTab label="Settings">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source='settings' onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

        {/* --- Trigger --- */}
        <FormTab label="Trigger">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source='trigger' onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

        {/* --- UI --- */}
        <FormTab label="UI (old)">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source='ui' onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

        {/* --- Fetch --- */}
        <FormTab label="Fetch">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source='fetch' onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

        {/* --- Message --- */}
        <FormTab label="Message (old)">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source='message' onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

        {/* --- Sequence --- */}
        <FormTab label="Sequence">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source='sequence' onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>
      </TabbedForm>
      <EditorCheatSheet/>
    </Edit>
  );
}

// --------------------------------- Create ---------------------------------
// Todo

const useStyles = makeStyles()((theme) => ({
  buttonRow: {
    flexDirection: 'row',
  }
}));

