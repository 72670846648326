import {defaultTheme} from 'react-admin';

export const juiceTheme = {
  ...defaultTheme,
  palette: {
    common: {
      black: {main: '#303030'},
      white: {main: '#ffffff'},
    },
    primary: {main: '#FF5151'},
    secondary: {main: '#638596'},
    error: {main: '#ff0000'},
    contrastThreshold: 0,
    tonalOffset: 0,
    background: {
      paper: '#FBF9F6',
      default: '#ffffff'
    },
  },
  shape: {borderRadius: 10},
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Quicksand', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
  overrides: {
    MuiPaper: {
      root: {
        boxShadow: undefined,
        marginBottom: 10,
        // marginTop: 10,
      },
    },
    // MuiTable: {
    //   root: {
    //     backgroundColor: 'white',
    //   }
    // },
    MuiButton: { // override the styles of all instances of this component
      root: {
        margin: 10,
        borderRadius: 50
      },
      textSizeSmall: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
      },
      textPrimary: {
        color: 'white',
        backgroundColor: '#FF5151',
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        backgroundColor: 'white',
        borderBottomWidth: 0,
      },
      underline: {
        "&:before": {
          borderBottomWidth: 0,
        },
        "&:after": {
          borderBottomWidth: 0,
        }
      }
    },

  },
};
